import ArrowCircle from 'components/arrow-circle';
import createSVGElement from 'utils/create-svg-element';

export default class WindIcon {
  constructor({ units, el, precision = 5, size = 38 }) {
    this.units = units;

    if (el) {
      this.el = el;
      this.precision = parseInt(el.dataset.precision);
      this.circle = el.querySelector('circle');
      this.arrow = new ArrowCircle(el.querySelector('.wind-icon__arrow'));
      this.textEl = el.querySelector('.wind-icon__val');

      this.setSpeed(el.dataset.speed);
      this.renderSpeed();
    } else {
      this.precision = precision;
      this.create({ size });
    }

    units.onChange(() => this.renderSpeed());
  }

  setSpeed(speedStr) {
    const s = parseFloat(speedStr);
    this.speed = Number.isNaN(s) ? null : s;
  }

  renderSpeed() {
    this.textEl.textContent = this.speed !== null
      ? this.units.formatSpeed(this.speed, { precision: this.precision })
      : '-';
  }

  create({ size }) {
    this.el = document.createElement('div');
    this.el.classList.add('wind-icon');

    const svg = document.createElement('svg');
    svg.setAttribute('viewBox', `${- size / 2} ${- size / 2} ${size} ${size}`);
    svg.setAttribute('fill', 'rgb(0, 0, 0)');
    svg.setAttribute('class', 'wind-icon__svg');
    this.el.appendChild(svg);

    this.circle = createSVGElement('circle');
    this.circle.setAttribute('cx', 0);
    this.circle.setAttribute('cy', 0);
    svg.appendChild(this.circle);

    this.arrow = new ArrowCircle();
    this.arrow.el.setAttribute('class', 'wind-icon__arrow');
    svg.appendChild(this.arrow.el);

    this.textEl = createSVGElement('text');
    this.textEl.setAttribute('class', 'wind-icon__val');
    this.textEl.setAttribute('x', 0);
    this.textEl.setAttribute('y', 5);
    this.textEl.setAttribute('text-anchor', 'middle');
    svg.appendChild(this.textEl);
  }

  update({ speed, angle }) {
    const speedf = parseFloat(speed);
    const radius = Number.isNaN(speedf) || speedf <= 100 ? 10.5 : 12.5;
    const attrs = {
      arrowheadHeight: 6,
      connectorThickness: 4,
      radius,
      angle,
    };

    this.circle.setAttribute('r', radius);

    if (angle !== undefined) {
      if (speedf <= 100) {
        attrs.connectorHeight = 4;
        attrs.arrowheadThickness = 14;
      } else {
        attrs.connectorHeight = 2.5;
        attrs.arrowheadThickness = 18;
      }
    }

    this.arrow.update(attrs);

    if (speed !== undefined) {
      this.setSpeed(speed);
      this.renderSpeed();
      this.circle.setAttribute('fill', this.circleColor());
      this.textEl.setAttribute('fill', this.textColor());
    }
  }

  textColor() {
    if (!this.speed || this.speed < 20) return 'rgb(255, 255, 255)';
    if (this.speed < 40) return 'rgb(0, 255, 0)';
    if (this.speed < 60) return 'rgb(255, 255, 0)';
    if (this.speed < 80) return 'rgb(255, 180, 0)';

    return 'rgb(255, 0, 0)';
  }

  circleColor() {
    if (!this.speed || this.speed < 100) return 'rgb(0, 0, 0)';

    return 'rgb(255, 0, 0)';
  }
}
