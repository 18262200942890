require.context('./static', true, /\.png$/);
import './index.scss';
import WindIcon from './wind-icon';

export { WindIcon };

const mapping = [];

export function windIconFor(el, units) {
  let entry = mapping.find(m => m[0] === el);
  if (!entry) {
    entry = [el, new WindIcon({ el, units })]
    mapping.push(entry);
  }
  return entry[1];
}

export default function(container, units) {
  return [...container.querySelectorAll('.wind-icon')].map(el =>
    windIconFor(el, units)
  );
}
